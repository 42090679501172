import React from "react";
import { Box, Typography, Container, Stack, IconButton } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";

const About = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body1" component="p">
          Welcome to our website!
        </Typography>
        <Typography variant="body1" component="p">
          This website is under development, but soon it will showcase our
          projects and experiences in the world of technology and tea.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
          Connect with us
        </Typography>
        <Stack direction="row" spacing={2}>
          <IconButton
            aria-label="LinkedIn"
            color="primary"
            href="https://www.linkedin.com/in/anuragrai7/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon fontSize="large" />
          </IconButton>
          <IconButton
            aria-label="GitHub"
            color="primary"
            href="https://github.com/anurag-rai"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GitHubIcon fontSize="large" />
          </IconButton>
          <IconButton
            aria-label="Email"
            color="primary"
            href="mailto:7anuragrai7@gmail.com"
          >
            <EmailIcon fontSize="large" />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
};

export default About;
