import React from "react";
import AppHeader from "../AppHeader/AppHeader";

const Layout = ({ children }) => {
  return (
    <>
      <AppHeader />
      {children}
    </>
  );
};

export default Layout;
