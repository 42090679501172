import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Layout from "./components/Layout/Layout";
import TeaMap from "./components/TeaMap/TeaMap";
import TeaData from "./components/TeaData/TeaData";
import NotFound from "./components/NotFound/NotFound";
import About from "./components/About/About";

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/map" element={<TeaMap />} />
          <Route path="/tea-data" element={<TeaData />} />
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Navigate to="/map" replace />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
