import React from "react";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const AppHeader = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          The Darjeeling Tea Project
        </Typography>
        <Button color="inherit" component={Link} to="/map">
          Map
        </Button>
        <Button color="inherit" component={Link} to="/tea-data">
          Tea Data
        </Button>
        <Button color="inherit" component={Link} to="/about">
          About
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
