import React, { useState } from "react";
import {
  Container,
  Typography,
  Select,
  MenuItem,
  Stack,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
} from "@mui/material";
import sectionsData from "../../data/sectionData.json";

const TeaData = () => {
  const [selectedSection, setSelectedSection] = useState(
    sectionsData.sections[0].name
  );

  const currentSection = sectionsData.sections.find(
    (section) => section.name === selectedSection
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 2 }}>
      <Typography variant="h4" gutterBottom>
        Tea Garden Section Data
      </Typography>
      <Select
        value={selectedSection}
        onChange={(e) => setSelectedSection(e.target.value)}
        fullWidth
        margin="normal"
      >
        {sectionsData.sections.map((section) => (
          <MenuItem key={section.name} value={section.name}>
            {section.name}
          </MenuItem>
        ))}
      </Select>

      {currentSection?.details && (
        <Stack spacing={3} mt={2}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              Natural & History
            </Typography>
            {currentSection.details.naturalAndHistory && (
              <>
                <Typography variant="body2" italic>
                  Updated:{" "}
                  {currentSection.details.naturalAndHistory.metadata.updatedAt}{" "}
                  | Revision:{" "}
                  {
                    currentSection.details.naturalAndHistory.metadata
                      .revisionNumber
                  }
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body1" component="p">
                  <strong>Meaning and History:</strong>{" "}
                  {currentSection.details.naturalAndHistory.meaningAndHistory}
                </Typography>
                <Typography variant="body1" component="p">
                  <strong>Landform and Vegetation:</strong>{" "}
                  {
                    currentSection.details.naturalAndHistory
                      .landformAndVegetation
                  }
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <strong>Year of Planting</strong>
                        </TableCell>
                        <TableCell>
                          {
                            currentSection.details.naturalAndHistory
                              .yearOfPlanting
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Elevation (High/Low/Average)</strong>
                        </TableCell>
                        <TableCell>
                          {
                            currentSection.details.naturalAndHistory
                              .elevationHigh
                          }{" "}
                          /{" "}
                          {
                            currentSection.details.naturalAndHistory
                              .elevationLow
                          }{" "}
                          /{" "}
                          {
                            currentSection.details.naturalAndHistory
                              .elevationAverage
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Territory</strong>
                        </TableCell>
                        <TableCell>
                          {currentSection.details.naturalAndHistory.territory}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Main Facing / Sub Facing</strong>
                        </TableCell>
                        <TableCell>
                          {currentSection.details.naturalAndHistory.mainFacing}{" "}
                          / {currentSection.details.naturalAndHistory.subFacing}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="body1" mt={2}>
                  <strong>Soil Profile:</strong>{" "}
                  {currentSection.details.naturalAndHistory.soilProfile}
                </Typography>
              </>
            )}
          </Paper>

          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              Field
            </Typography>
            {currentSection.details.field && (
              <>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body1" component="p">
                  <strong>Agronomic Characteristics:</strong>{" "}
                  {currentSection.details.field.agronomicCharacteristics}
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <strong>Main Jat / Cultivar</strong>
                        </TableCell>
                        <TableCell>
                          {currentSection.details.field.mainJatCultivar}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Spacing</strong>
                        </TableCell>
                        <TableCell>
                          {currentSection.details.field.spacing}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Plant Per HAC</strong>
                        </TableCell>
                        <TableCell>
                          {currentSection.details.field.plantPerHAC}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Irrigation</strong>
                        </TableCell>
                        <TableCell>
                          {currentSection.details.field.irrigation}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Fertilizer</strong>
                        </TableCell>
                        <TableCell>
                          {currentSection.details.field.fertilizer}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  {currentSection.details.field.pruningCycle.title}
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong>Year</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Month</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Cycle</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Note</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentSection.details.field.pruningCycle.data.map(
                        (cycle, index) => (
                          <TableRow key={cycle.year}>
                            <TableCell>{cycle.year}</TableCell>
                            <TableCell>{cycle.month}</TableCell>
                            <TableCell>{cycle.cycle}</TableCell>
                            <TableCell>{cycle.note}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  {currentSection.details.field.bushOverView.title}
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong>Viriety</strong>
                        </TableCell>
                        <TableCell>
                          <strong>% or Ha</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Note</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentSection.details.field.bushOverView.data.map(
                        (d) => (
                          <TableRow key={d.viriety}>
                            <TableCell>{d.viriety}</TableCell>
                            <TableCell>{d.percentageOrHA}</TableCell>
                            <TableCell>{d.note}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Paper>
        </Stack>
      )}
    </Container>
  );
};

export default TeaData;
