import "./TeaMap.css";
import React, { useState, useCallback } from "react";
import { Stack } from "@mui/material";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  AdvancedMarkerAnchorPoint,
  Pin,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { GOOGLE_MAPS_API_KEY, LAT_LONG_MAP } from "../../utils/constants";

const data = [
  {
    id: String(0),
    position: LAT_LONG_MAP.RISHIHAT,
    zIndex: 0,
    type: "pin",
  },
  {
    id: String(1),
    position: LAT_LONG_MAP.NEAR_RISHIHAT,
    zIndex: 1,
    type: "html",
  },
]
  .sort((a, b) => b.position.lat - a.position.lat)
  .map((dataItem, index) => ({ ...dataItem, zIndex: index }));

const Z_INDEX_SELECTED = data.length;
const Z_INDEX_HOVER = data.length + 1;

const TeaMap = () => {
  const [markers] = useState(data);

  const [hoverId, setHoverId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const anchorPoint = "BOTTOM";
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [infoWindowShown, setInfoWindowShown] = useState(false);

  const onMouseEnter = useCallback((id) => setHoverId(id), []);
  const onMouseLeave = useCallback(() => setHoverId(null), []);
  const onMarkerClick = useCallback(
    (id, marker) => {
      setSelectedId(id);

      if (marker) {
        setSelectedMarker(marker);
      }

      if (id !== selectedId) {
        setInfoWindowShown(true);
      } else {
        setInfoWindowShown((isShown) => !isShown);
      }
    },
    [selectedId]
  );

  const onMapClick = useCallback(() => {
    setSelectedId(null);
    setSelectedMarker(null);
    setInfoWindowShown(false);
  }, []);

  const handleInfowindowCloseClick = useCallback(() => {
    setSelectedId(null);
    setSelectedMarker(null);
    setInfoWindowShown(false);
  }, []);

  return (
    <Stack style={{ marginTop: 20 }}>
      <APIProvider apiKey={GOOGLE_MAPS_API_KEY} libraries={["marker"]}>
        <Map
          style={{ height: "85vh" }}
          mapId={"1c6d4c11550c70e9"}
          defaultZoom={15}
          defaultCenter={LAT_LONG_MAP.RISHIHAT}
          gestureHandling={"greedy"}
          onClick={onMapClick}
          clickableIcons={false}
          disableDefaultUI
        >
          {markers.map(({ id, zIndex: zIndexDefault, position, type }) => {
            let zIndex = zIndexDefault;

            if (hoverId === id) {
              zIndex = Z_INDEX_HOVER;
            }

            if (selectedId === id) {
              zIndex = Z_INDEX_SELECTED;
            }

            if (type === "pin") {
              return (
                <AdvancedMarkerWithRef
                  onMarkerClick={(marker) => onMarkerClick(id, marker)}
                  onMouseEnter={() => onMouseEnter(id)}
                  onMouseLeave={onMouseLeave}
                  key={id}
                  zIndex={zIndex}
                  className="custom-marker"
                  style={{
                    transform: `scale(${
                      [hoverId, selectedId].includes(id) ? 1.4 : 1
                    })`,
                  }}
                  position={position}
                >
                  <Pin
                    background={selectedId === id ? "#22ccff" : null}
                    borderColor={selectedId === id ? "#1e89a1" : null}
                    glyphColor={selectedId === id ? "#0f677a" : null}
                  />
                </AdvancedMarkerWithRef>
              );
            } else {
              // type === 'html'
              return (
                <React.Fragment key={id}>
                  <AdvancedMarkerWithRef
                    position={position}
                    zIndex={zIndex}
                    anchorPoint={AdvancedMarkerAnchorPoint[anchorPoint]}
                    className="custom-marker"
                    style={{
                      transform: `scale(${
                        [hoverId, selectedId].includes(id) ? 1.4 : 1
                      })`,
                    }}
                    onMarkerClick={(marker) => onMarkerClick(id, marker)}
                    onMouseEnter={() => onMouseEnter(id)}
                    onMouseLeave={onMouseLeave}
                  >
                    <div
                      className={`custom-html-content ${
                        selectedId === id ? "selected" : ""
                      }`}
                    ></div>
                  </AdvancedMarkerWithRef>

                  {/* anchor point visualization marker */}
                  <AdvancedMarkerWithRef
                    onMarkerClick={(marker) => onMarkerClick(id, marker)}
                    zIndex={zIndex}
                    onMouseEnter={() => onMouseEnter(id)}
                    onMouseLeave={onMouseLeave}
                    anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
                    position={position}
                  >
                    <div className="visualization-marker"></div>
                  </AdvancedMarkerWithRef>
                </React.Fragment>
              );
            }
          })}

          {infoWindowShown && selectedMarker && (
            <InfoWindow
              anchor={selectedMarker}
              onCloseClick={handleInfowindowCloseClick}
            >
              <h2>Marker {selectedId}</h2>
              <p>TODO: Render some things about this marker</p>
            </InfoWindow>
          )}
        </Map>
      </APIProvider>
    </Stack>
  );
};

export const AdvancedMarkerWithRef = (props) => {
  const { children, onMarkerClick, ...advancedMarkerProps } = props;
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <AdvancedMarker
      onClick={() => {
        if (marker) {
          onMarkerClick(marker);
        }
      }}
      ref={markerRef}
      {...advancedMarkerProps}
    >
      {children}
    </AdvancedMarker>
  );
};

export default TeaMap;
