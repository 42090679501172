export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const LAT_LONG_MAP = {
  RISHIHAT: {
    lat: 27.041593600282482,
    lng: 88.2192241573078,
  },
  NEAR_RISHIHAT: {
    lat: 27.03764,
    lng: 88.22451,
  },
};
